import React from 'react'
import SEO from '../components/seo'
import * as commonPageStyles from '../styles/common-page.module.css'

const NaukaSpiewuPage = () => (
  <>
    <SEO title="Nauka gry na pianinie w Jaworznie - najlepsze lekcje w mieście!"
    descritpion="Nauka gry na pianinie w Jaworznie! Sprawdź naszą ofertę i zapisz się na indywidualne lekcje gry, rozwijając swoje umiejętności." />
    <section className="sub-page-header">
      <div className="wrapper">
        <h1>Nauka Gry na Pianinie Jaworzno </h1>
      </div>
    </section>

    <div className={`${commonPageStyles.sectionsContainer} wrapper`}>
      <section>
        <h2>Nauka gry na pianinie w Jaworznie – Zacznij swoją muzyczną podróż już dziś!</h2>
        <p>
        Czy marzysz o opanowaniu sztuki gry na pianinie? Chciałbyś zagrać ulubione utwory klasyczne, popowe lub jazzowe? Nauka gry na pianinie w Music Spot to doskonała opcja dla osób w każdym wieku i na każdym poziomie zaawansowania. Nasza prywatna szkoła muzyczna oferuje profesjonalne lekcje pianina, dostosowane do indywidualnych potrzeb i celów każdego ucznia.
        </p>
      </section>
      <section>
        <h2>Dlaczego warto wybrać naszą szkołę muzyczną w Jaworznie?</h2>
        <p>
        Nasza szkoła muzyczna to miejsce, w którym nauka gry na pianinie staje się przyjemnością i wyzwaniem. Oferujemy kompleksowe i nowoczesne podejście do nauki gry na pianinie, bazujące na doświadczeniu naszych nauczycieli i nowoczesnych metodach edukacyjnych. Dzięki temu każdy z naszych uczniów może rozwijać swoje umiejętności w swoim tempie, niezależnie od tego, czy dopiero zaczyna swoją przygodę z muzyką, czy ma już pewne doświadczenie.
        </p>
      </section>
      <section>
        <h2>Indywidualne podejście do każdego ucznia</h2>
        <p>
        Jednym z naszych głównych atutów jest indywidualne podejście do każdego ucznia. Wiemy, że każda osoba ma inne potrzeby i cele, dlatego nasze lekcje gry na pianinie są dostosowane do możliwości i zainteresowań uczniów. Niezależnie od tego, czy interesuje Cię muzyka klasyczna, jazz, pop czy inne gatunki, nasz zespół doświadczonych nauczycieli pomoże Ci osiągnąć Twoje cele muzyczne.
        </p>
      </section>
      <section>
        <h2>Nauka gry na pianinie dla dzieci, młodzieży i dorosłych</h2>
        <p>
        Nasza szkoła muzyczna w Jaworznie oferuje naukę gry na pianinie dla dzieci, młodzieży oraz dorosłych. Wierzymy, że nigdy nie jest za późno ani za wcześnie, aby zacząć grać na pianinie. Każda grupa wiekowa znajdzie u nas program dostosowany do swoich potrzeb i możliwości. Dzięki odpowiedniemu podejściu dydaktycznemu, uczymy z pasją i zaangażowaniem, co przynosi wymierne efekty.
        </p>
      </section>
      <section>
        <h2>Zajęcia gry na pianinie w Jaworznie – zalety</h2>
        <p>
        Nauka gry na pianinie w naszej szkole to nie tylko rozwijanie umiejętności muzycznych, ale także sposób na poprawę koncentracji, pamięci oraz wyrażanie emocji. Nasze zajęcia pomagają rozwinąć umiejętności manualne, a także wzmacniają pewność siebie u dzieci i dorosłych. Nasi uczniowie regularnie biorą udział w koncertach i występach, co stanowi świetną okazję do pokazania zdobytych umiejętności przed publicznością.
        </p>
      </section>
      <section>
        <h2>Lekcje gry na pianinie w Jaworznie – Elastyczność i dostosowanie do Twojego harmonogramu</h2>
        <p>
        Nasza szkoła muzyczna oferuje lekcje gry na pianinie w dogodnych godzinach, co pozwala na elastyczne dostosowanie nauki do Twojego planu dnia. Oferujemy zarówno lekcje stacjonarne w naszej siedzibie w Jaworznie, jak i lekcje online, co daje możliwość nauki z każdego miejsca. Dzięki temu, niezależnie od Twojego stylu życia, znajdziesz odpowiednią formę zajęć.
        </p>
      </section>
      <section>
        <h2>Profesjonalni nauczyciele gry na pianinie</h2>
        <p>
        W naszej szkole pracują wyłącznie wykwalifikowani i doświadczeni nauczyciele, którzy nie tylko posiadają szeroką wiedzę teoretyczną, ale także praktyczne doświadczenie sceniczne. Nauka gry na pianinie pod okiem naszych profesjonalistów to gwarancja szybkich postępów i satysfakcji z osiąganych rezultatów.
        </p>
      </section>
      <section>
        <h2>Nauka gry na pianinie – Jak wygląda proces?</h2>
        <p>
        Nasze lekcje gry na pianinie w Jaworznie zaczynają się od oceny Twojego poziomu zaawansowania i ustalenia indywidualnego planu nauki. W zależności od Twoich umiejętności, będziemy pracować nad techniką, teorią muzyki, czytaniem nut, a także interpretacją utworów. Każde zajęcia są dostosowane do Twojego tempa nauki i celów muzycznych. Dzięki temu możesz cieszyć się nauką i widzieć systematyczne postępy.
        </p>
      </section>
      <section>
        <h2>CNauka gry na pianinie w Jaworznie – Korzyści</h2>
        <p>
        Podjęcie decyzji o nauce gry na pianinie w Music Spot to inwestycja w rozwój umiejętności, która przynosi wiele korzyści. Gra na pianinie rozwija kreatywność, poprawia zdolności analityczne, a także redukuje stres. Jest to również doskonały sposób na wyrażenie siebie oraz sposób na spędzanie czasu w twórczy i satysfakcjonujący sposób.
        </p>
      </section>
      <section>
        <h2>Zapisz się na lekcje gry na pianinie w Jaworznie</h2>
        <p>
        Jeśli marzysz o nauce gry na pianinie i chcesz rozwijać swoje muzyczne talenty, zapraszamy do naszej szkoły muzycznej w Jaworznie. Oferujemy lekcje dla osób w każdym wieku i na każdym poziomie zaawansowania. Nasza oferta jest elastyczna i dostosowana do Twoich potrzeb, aby zapewnić Ci najlepsze możliwe rezultaty. Nauka gry na pianiniejeszcze nigdy nie była tak dostępna i przyjemna!

        </p>
      </section>
     
     

      <section style={{ marginTop: '70px', marginBottom: '50px' }}>
        <p>
          <h2>Zarezerwuj swoją pierwszą lekcję już dziś i dołącz do grona zadowolonych uczniów, którzy odkryli radość płynącą z gry na pianinie. Nauka gry na pianinie w Jaworznie czeka na Ciebie!</h2>
        </p>
      </section>
    </div>
  </>
)

export default NaukaSpiewuPage
